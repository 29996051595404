import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import EvolvedCard from './EvolvedCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Heading2 } from './common/Heading2';
import styled from '@emotion/styled';
const ReviewPageList = ({ heading }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allContentfulReviewPage(
          limit: 10
          filter: { shouldNotBeListedOnHomepage: { ne: true } }
          sort: { order: DESC, fields: updatedAt }
        ) {
          nodes {
            shortName
            slug
            contentful_id
            __typename
            featureImage {
              file {
                url
              }
              gatsbyImageData(
                quality: 90
                width: 160
                height: 127
                layout: CONSTRAINED
              )
            }
            updatedAt
          }
        }
      }
    `}
    render={data => {
      const reviewContent = data.allContentfulReviewPage.nodes;
      return (
        <Container>
          {heading && <SHeading2>{heading}</SHeading2>}
          <Grid container spacing={4}>
            {reviewContent.map(item => (
              <Grid item key={item.contentful_id} xs={12} md={6}>
                <EvolvedCard
                  title={item.shortName}
                  slug={`${item.slug}/`}
                  image={item.featureImage}
                  updated={item.updatedAt}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      );
    }}
  />
);

export default ReviewPageList;

const SHeading2 = styled(Heading2)`
  margin-bottom: var(--medium);
  font-size: 2em;
`;
