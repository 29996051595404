import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LinkList } from './LinkList';

const CalculatorPageList = ({ heading }) => {
  const { allContentfulCalculatorPage } = useStaticQuery(graphql`
    {
      allContentfulCalculatorPage {
        nodes {
          shortName
          slug
          contentful_id
          __typename
          updatedAt
        }
      }
    }
  `);
  const reviewContent = allContentfulCalculatorPage.nodes;

  return <LinkList heading={heading} data={reviewContent} />;
};

export default CalculatorPageList;
