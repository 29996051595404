import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { Link, navigate } from 'gatsby';
import { formatDateString } from '../utils/date';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const SCardHeading = styled(Typography)`
  text-decoration: none;
  color: black;
`;

const SCardActionArea = styled(CardActionArea)``;

const SKR = styled(ArrowForwardIcon)`
  vertical-align: middle;

  ${SCardActionArea}:hover &,
  ${SCardActionArea}:focus & {
    color: var(--color-primary);
  }
`;
const GoTo = ({ text }) => (
  <>
    {text} <SKR />
  </>
);

const EvolvedCard = ({ title, slug, image, updated }) => {
  const linkSlug = `/${slug}`;

  const onActionAreaClick = () => {
    navigate(linkSlug);
  };

  return (
    <SCardActionArea onClick={onActionAreaClick}>
      <SCard elevation={0}>
        <SCardContent>
          <SCardHeading
            component={Link}
            variant="h5"
            to={linkSlug}
            tabIndex={-1}
          >
            {title}
          </SCardHeading>
          <Typography variant="subtitle1" component="p" color="textSecondary">
            Oppdatert: {formatDateString(updated)}
          </Typography>
          <Typography variant="subtitle1" component="p" color="secondary">
            <GoTo text="Til testen" />
          </Typography>
        </SCardContent>
        {image && <SGatsbyImage image={getImage(image)} />}
      </SCard>
    </SCardActionArea>
  );
};

export default EvolvedCard;

const SCard = styled(Card)`
  display: flex;
`;

const SCardContent = styled(CardContent)`
  flex-grow: 1;
`;

const SGatsbyImage = styled(GatsbyImage)`
  width: 160px;
`;
