import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ReviewPageList from '../components/ReviewPageList';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import CalculatorPageList from '../components/CalculatorList';
import { FeatureHomepageLinks } from '../components/FeaturedHomepageLinks';

const IndexPage = () => {
  const {
    contentfulFrontpage: { metaTitle, metaDescription, headerImage },
  } = useStaticQuery(graphql`
    {
      contentfulFrontpage {
        h1
        metaTitle
        metaDescription
        headerImage {
          url {
            url
          }
          altTag
          image {
            gatsbyImageData(quality: 90, width: 1600, layout: CONSTRAINED)
          }
        }
      }
    }
  `);
  const maybeUrl = headerImage?.url?.url;
  return (
    <Layout>
      <SEO
        title={metaTitle}
        metaDescription={metaDescription}
        meta={[
          {
            name: 'verification',
            content: 'b229626d0a8dc6eeffc9c0e584e5ac70',
            'data-react-helmet': false,
          },
        ]}
      />
      {maybeUrl ? (
        <a href={maybeUrl} target="_blank" rel="nofollow noreferrer">
          <GatsbyImage
            image={getImage(headerImage.image)}
            alt={headerImage.altTag}
          />
        </a>
      ) : (
        <GatsbyImage
          image={getImage(headerImage.image)}
          alt={headerImage.altTag}
        />
      )}

      <FeatureHomepageLinks heading="Favoritter" />
      {maybeUrl && (
        <a href={maybeUrl} target="_blank" rel="nofollow noreferrer">
          <GatsbyImage
            image={getImage(headerImage.image)}
            alt={headerImage.altTag}
          />
        </a>
      )}
      <ReviewPageList heading="Sist oppdaterte" />
      {maybeUrl && (
        <a href={maybeUrl} target="_blank" rel="nofollow noreferrer">
          <GatsbyImage
            image={getImage(headerImage.image)}
            alt={headerImage.altTag}
          />
        </a>
      )}
      <CalculatorPageList heading="Kalkulatorer og verktøy" />
    </Layout>
  );
};

export default IndexPage;
