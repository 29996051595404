const formatDateString = dateString => {
  const date = new Date(dateString);
  return date.toLocaleDateString('nb-NO', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export { formatDateString };
