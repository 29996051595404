import React from 'react';
import Container from '@mui/material/Container';
import { Typography, Paper } from '@mui/material';
import { flexColumn } from './common/styled';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';

const Sul = styled(motion.ul)`
  padding: var(--medium);
  list-style: none;
  ${flexColumn};
`;

const Sli = styled(motion.li)`
  margin: 0 auto;
  padding: 3px;
  font-size: 2em;

  a {
    text-decoration: none;
  }
`;

const SContainer = styled(Container)`
  && {
    ${flexColumn};

    background: #1565c0;
    padding: var(--small);
    margin: var(--medium) 0;

    & h2 {
      margin: 0 auto;
      color: white;
    }
    & a {
      color: white;
    }
    & a:hover {
      text-decoration: underline;
    }
    & a:focus {
      border: 2px dotted #d500f9;
      padding: 5px;
    }
  }
`;

export const LinkList: React.FC<{
  heading: string;
  data: {
    shortName: string;
    slug: string;
    contentful_id: string;
    updatedAt: string;
  }[];
}> = ({ heading, data }) => {
  const list = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
    hidden: {
      opacity: 0,
    },
  };
  const listItem = {
    visible: { x: 0, opacity: 1 },
    hidden: {
      x: -500,
      opacity: 0,
    },
  };

  return (
    <SContainer as={Paper}>
      {heading && (
        <Div
        >
          <Typography component="h2" variant="h4">
            {heading}
          </Typography>
        </Div>
      )}
      <Sul variants={list} animate="visible" initial="hidden">
        {data.map(item => (
          <Sli key={item.contentful_id} variants={listItem}>
            <Link to={`/${item.slug}/`}>{item.shortName}</Link>
          </Sli>
        ))}
      </Sul>
    </SContainer>
  );
};

const Div = styled.div`
  display:flex;
  justify-content:center;
  border-bottom: 2px solid red;
  border-radius: 20%;
`